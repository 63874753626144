import React from 'react'
import './../App.css';
import NavbarComp from './../component/NavbarComp';
import FooterComp from './../component/FooterComp';
import { Container, Row, Col, Button } from 'react-bootstrap';

const TutorialPages = () => {
    return (
        <>
            <NavbarComp></NavbarComp>
            tutorial
            <FooterComp></FooterComp>
        </>
    )
}
export default TutorialPages