import React from 'react'
import {Card,Button} from 'react-bootstrap'
const PricingComp = ({data}) => {
    console.log("datasss",data)
    return (
        <>
            <Card className="text-center">
                <Card.Header>Paket</Card.Header>
                <Card.Body style={{paddingBottom:'150px',paddingTop:'50px'}}>
                    <h3>{data.paket}</h3>
                    <Card.Title>{data.price}</Card.Title>
                    <Card.Text className='mt-5'>
                    {data.desc}
                    </Card.Text>
                    <Button variant="primary" href="/register">Pilih Paket</Button>
                </Card.Body>
                {/* <Card.Footer className="text-muted">2 days ago</Card.Footer> */}
            </Card>
        </>
    )
}

export default PricingComp