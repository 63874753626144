import axios from 'axios'


const axiosInstance = axios.create({
    withCredentials: true,
    baseURL: process.env.REACT_APP_API_BASE_URL
})

const register = (data) => {
    return axiosInstance.post(`/api/v1/auth/register`,data)
}

const checkStore = (storename) => {
    return axiosInstance.get(`/api/v1/public/storename/status/${storename}`)
}

export {register,checkStore}
