import react,{useState} from 'react'
import FooterComp from '../component/FooterComp'
import JumbotronComp from '../component/JumbotronComp'
import NavbarComp from '../component/NavbarComp'
const PendingPaymentPages = () => {
    let search = window.location.search;
    let params = new URLSearchParams(search);
   
    const [orderId,setOrderId] = useState(params.get('order_id'))
    const [pdfUrl,setPdfUrl] = useState(params.get('pdf_url'))
    return <>
        <NavbarComp></NavbarComp>
        <JumbotronComp data={{
            title: `Kode Order ${orderId} Silahkan lakukan Pembayaran`,
            desc: "Buat Website Undangan Sebanyak yang kamu mau",
            button: "Cara Membayar",
            img: "https://d3vqnu8iq57j2l.cloudfront.net/1643688710915-happycouple.png",
            link:pdfUrl
        }}></JumbotronComp>
        <FooterComp></FooterComp>
    </>
}

export default PendingPaymentPages
