import React from 'react'
import { Button, Col, Container, Row } from 'react-bootstrap';
import './../App.css';
const JumbotronComp = ({data}) => {
    return <>
        <Container style={{background:'#007BFD'}}>
            <Row>
                <Col md={6}>
                    <h1 className='heading' style={{color:'#fff'}}> {data.title}
                    </h1>
                    <p style={{color:'#fff'}}>{data.desc} </p>
                    <Button href={data.link}>{data.button}</Button>
                    <div className="ml-4 mt-3">
                        <a href="https://play.google.com/store/apps/details?id=id.retailo.my" target="_blank" rel="noreferrer"><img src="https://d3vqnu8iq57j2l.cloudfront.net/1637651944556-googleplay.png" width="160" /></a>
                      </div>
                </Col>
                <Col md={6}>
                    <img src={data.img} className='img-fluid' />
                    
                </Col>
            </Row>
        </Container>
    </>
}

export default JumbotronComp