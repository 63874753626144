import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";
import FeaturesPages from "./pages/FeaturePages";
import HomePages from './pages/HomePages';
import PendingPaymentPages from "./pages/PendingPaymentPages";
import PricingPages from "./pages/PricingPages";
import RegisterPages from "./pages/RegisterPages";
import TutorialPages from "./pages/TutorialPages";
function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<HomePages />} />
        <Route path="/feature" element={<FeaturesPages />} />
        <Route path="/tutorial" element={<TutorialPages />} />
        <Route path="/pricing" element={<PricingPages />} />
        <Route path="/register" element={<RegisterPages />} />
        <Route path="/payment/pending" element={<PendingPaymentPages />} />
      </Routes>
    </BrowserRouter>

  );
}

export default App;
