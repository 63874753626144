import React from 'react'
import './../App.css';
import NavbarComp from './../component/NavbarComp';
import FooterComp from './../component/FooterComp';
import { Container, Row, Col, Button } from 'react-bootstrap';
import FeatureComp from '../component/FeatureComp';

const FeaturesPages = () => {
    return (
        <>
            <NavbarComp></NavbarComp>

            <Container>   
                <Row>
                    <Col md={4}>
                        <FeatureComp data={{
                            title: 'Hitungan Mundur',
                            desc: 'Jangan sampai terlewatkan moment indah kamu, hitung mundur acara pernikahanmu',
                            icon: 'fas fa-stopwatch fa-3x'
                        }}></FeatureComp>
                    </Col>
                    <Col md={4}>
                        <FeatureComp data={{
                            title: 'Design Modern & Responsif',
                            desc: 'Undangan nikah yang modern dan bisa tampil disegala ukuran layar',
                            icon: 'fas fa-mobile fa-3x'
                        }}></FeatureComp>
                    </Col>
                    <Col md={4}>
                        <FeatureComp data={{
                            title: 'Menambahkan ke Kalendar',
                            desc: 'Ingatkan Acara untuk para tamu melalui google Kalendar',
                            icon: 'fas fa-calendar fa-3x'
                        }}></FeatureComp>
                    </Col>
                    <Col md={4}>
                        <FeatureComp data={{
                            title:'Realtime Editing',
                            desc: 'Para Tamu dapat melihat undangan ketika editor menyimpan halaman',
                            icon: 'fas fa-paint-brush fa-3x'
                        }}></FeatureComp>
                    </Col>
                    <Col md={4}>
                        <FeatureComp data={{
                            title:'Gallery Foto',
                            desc: 'Bagikan momen indah bersama pasangan untuk berbagi kisah ke undangan',
                            icon: 'fas fa-photo-video fa-3x'
                        }}></FeatureComp>
                    </Col>
                    <Col md={4}>
                        <FeatureComp data={{
                            title:'Navigasi Lokasi',
                            desc: 'Berikan Peta yang menuju ke lokasi agar memudahkan tamu ketempat tujuan',
                            icon: 'fas fa-compass fa-3x'
                        }}></FeatureComp>
                    </Col>
                    <Col md={4}>
                        <FeatureComp data={{
                            title:'Background Music',
                            desc: 'Berikan Music agar undanganmu lebih ekslusif dan memberikan suasana indah',
                            icon: 'fas fa-music fa-3x'
                        }}></FeatureComp>
                    </Col>
                    <Col md={4}>
                        <FeatureComp data={{
                            title:'Beri Ucapan',
                            desc: 'Para tamu dapat memberikan ucapan selamat atas pernikahan yang akan berlangsung',
                            icon: 'fas fa-comment fa-3x'
                        }}></FeatureComp>
                    </Col>
                </Row>
            </Container>
            <FooterComp></FooterComp>
        </>
    )
}
export default FeaturesPages