import React from 'react'
import { Accordion } from 'react-bootstrap'
const FaqComp = () => {
    return (
        <>
            <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                    <Accordion.Header style={{background:'#F5FAFF',color:'#3C4759'}}>Apa itu idinvitebook.com</Accordion.Header>
                    <Accordion.Body style={{background:'#F5FAFF',color:'#3C4759'}}>
                    IDInviteBook merupakan platform yang memudahkan Vendor/Personal untuk membuat website undangan nikah yang bisa diakses siapapun
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                    <Accordion.Header>Bagaimana Cara Daftar idinvitebook?</Accordion.Header>
                    <Accordion.Body>
                    Cukup klik tombol daftar, kemudian isi data sesuai form, jika sudah silahkan login
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                    <Accordion.Header>Berapa Biaya IdInvitebook?</Accordion.Header>
                    <Accordion.Body>
                    Biaya idinvitebook 399K untuk 12 bulan
                    </Accordion.Body>
                </Accordion.Item>
                
                <Accordion.Item eventKey="4">
                    <Accordion.Header>Apa saya bisa buat undangan sendiri ?</Accordion.Header>
                    <Accordion.Body>
                    kamu dapat membuat sendiri undangan dari awal atau cukup pilih design siap pakai yang telah kami buat
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="5">
                    <Accordion.Header>Berapa Masa Aktif undangan saya ?</Accordion.Header>
                    <Accordion.Body>
                    Undangan kamu akan selalu aktif selama kamu masih dalam status berlangganan
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </>
    )
}

export default FaqComp