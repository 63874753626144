import React, { useEffect, useState } from 'react'
import './../App.css';
import NavbarComp from './../component/NavbarComp';
import JumbotronComp from './../component/JumbotronComp';
import TestiComp from './../component/TestiComp';
import FooterComp from './../component/FooterComp';
import { Container, Row, Col, Button, Modal } from 'react-bootstrap';
import PricingComp from './../component/PricingComp';
import FaqComp from './../component/FaqComp';
import { useNavigate } from 'react-router-dom';
import FixedBottomComp from '../component/FixedBottomComp';
import YouTube from 'react-youtube';
import ThemeComp from '../component/ThemeComp';
import FeatureComp from '../component/FeatureComp';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBandAid, faClock, faCoffee, faEdit, faMapLocation, faMessage, faMoneyBill, faMusic, faPalette, faPallet, faPenClip, faPhotoFilm, faShare, faTable, faTablet, faTimes } from '@fortawesome/free-solid-svg-icons'
import PortfolioComp from '../component/PortfolioComp';
const HomePages = () => {
    const [portfolio, setPortfolio] = useState([
        {
            image: "https://d3vqnu8iq57j2l.cloudfront.net/1678623226937-aadf.png",
            title: "Rifky & Ninis",
            date: "4 Februari 2023",
            link: "https://idinvitebook.online/undangan/rifky-dan-ninis"
        },
        {
            image: "https://d3vqnu8iq57j2l.cloudfront.net/1678623400024-asdf.png",
            title: "Paice & Mira",
            date: "5 Februari 2023",
            link: "https://bakulstudio.com/undangan/paice--mira?to=romi"
        }
    ])
    const [y, setY] = useState(window.scrollY);

    let windowwidth = window.innerWidth
    const opts = {
        height: windowwidth > 640 ? '390' : '260',
        width: windowwidth > 640 ? '640' : '100%',
        playerVars: {
            // https://developers.google.com/youtube/player_parameters
            autoplay: 0,
        },
    };
    let navigate = useNavigate();

    const goToRegisterPage = () => {
        navigate('/register');
    }

    useEffect(() => {
        import("react-facebook-pixel")
            .then((x) => x.default)
            .then((ReactPixel) => {
                ReactPixel.init("1610568986009768");
                ReactPixel.track('ViewContent');

            });
    }, [])

    const handleNavigation = (value) => {
        if (window.pageYOffset > 100) {
            document.getElementById("navtop").style.background = "rgba(000,000,000,0.7)"
            document.getElementById("navtop").style.padding = "0px"
            document.getElementById("navtop").style.margin = "0px"

        } else {
            document.getElementById("navtop").style.background = "none"
            document.getElementById("navtop").style.padding = "10px"
        }

    }
    useEffect(() => {
        window.addEventListener("scroll", (e) => handleNavigation(e));

        return () => { // return a cleanup function to unregister our function since it's going to run multiple times
            window.removeEventListener("scroll", (e) => handleNavigation(e));
        };
    }, [y]);



    const Theme = ({ name, image }) => {
        return <div style={{ flex: 1, cursor: 'pointer', width: '100%', height: '360px', margin: '10px', float: 'left', overflow: 'hidden', backgroundImage: `url(${image})`, backgroundSize: 'cover' }} >

        </div>
    }

    return (
        <div >



            <NavbarComp></NavbarComp>
            <div style={{ background: 'url("https://d1jcw7e5n1q2pt.cloudfront.net/1704676186761-gau.png.png")', backgroundPosition: 'center center', overflow: 'hidden', maxHeight: '900px',backgroundSize:'cover' }}>


                <div id="home" style={{ background: 'rgba(000,000,000,0.6)', padding: '100px 0px' }} className="mobilecenter">
                    <Container>
                        <Row>

                            <Col md={5} sm={12} >
                                <h1 className='heading' style={{ color: '#fff', fontWeight: '800' }}>Website Undangan Pernikahan Online, <span style={{ color: '#F63854', fontWeight: 'bold' }}>Unlimited</span>
                                </h1>
                                <p style={{ color: '#fff', fontSize: '20px', marginTop: '20px' }}>Aplikasi ini ditujukan untuk kamu yang sering mendapat client website undangan pernikahan, Gak perlu lagi keluar duit kepihak ketiga!!

                                </p>
                                <p style={{ color: '#fff', fontSize: '20px', marginTop: '20px' }}>buat sendiri saja mudah!! buat berapapun jumlah undangannya,.. <span style={{ color: '#fff', fontWeight: 'bold' }}>Unlimited</span></p>
                                {/* <img src="https://d3vqnu8iq57j2l.cloudfront.net/1646031397369-ter.png" style={{ marginTop: '30px' }} /> */}
                                <br />
                                <Button onClick={() => goToRegisterPage()} className="mt-4">Buat Undangan Sekarang</Button>
                                <div className="ml-4 mt-3">
                                    {/* <a href="https://play.google.com/store/apps/details?id=id.retailo.my" target="_blank" rel="noreferrer"><img src="https://d3vqnu8iq57j2l.cloudfront.net/1637651944556-googleplay.png" width="160" /></a> */}
                                </div>
                            </Col>


                            <Col md={6} sm={12} >
                                <div style={{ position: 'relative', width: '100%', height: '100%' }}>
                                    {/* <img alt="undangan nikah digital" src="https://d3vqnu8iq57j2l.cloudfront.net/1676202300042-Undangan%20Digital%20%286%29.png" style={{ marginTop: '-50px', marginLeft: '100px', width: '900px' }} /> */}

                                </div>
                            </Col>

                        </Row>
                    </Container>
                </div>
            </div>


            <div style={{ padding: '130px 0px' }}>
                <Container >
                    <Row>

                        <Col md={7}>
                            <div className='text-center'>
                                <img src="https://d1jcw7e5n1q2pt.cloudfront.net/1704676344331--Desain-ta.png" className='img-fluid mt-3' style={{ marginTop: '-120px' }} alt="undangan nikah digital" />
                            </div>
                        </Col>
                        <Col md={5}>
                            <h2>Aplikasi Website Undangan Terbaik</h2>
                            <p className='mt-2 mb-5'>idinvitebook adalah aplikasi pembuatan website undangan online gratis terbaik yang sudah melayani pengguna sejak tahun 2020. Inilah alasan mereka memilih idinvitebook</p>
                            {/* <div style={{ display: 'flex' }}>
                                <div style={{ width: '100px' }} className="text-center">
                                    <div style={{ width: '50px', height: '50px', background: '#FF3190', textAlign: 'center', borderRadius: '100px', paddingTop: '13px' }}>
                                        <FontAwesomeIcon icon={faPhotoFilm} color="#fff" style={{ fontSize: '22px' }} />
                                    </div>
                                </div>
                                <div style={{ flex: 1 }}>
                                    <h5>Smart System</h5>
                                    <p>idinvitebook memiliki sistem yang mudah digunakan dan mendukung kustomisasi yang sangat fleksibel. Anda bisa mengatur undangan sesuai keinginan dan keperluan.</p>
                                </div>
                            </div> */}
                            <div style={{ display: 'flex' }}>
                                <div style={{ width: '100px' }}>
                                    <div style={{ width: '50px', height: '50px', background: '#FF3190', textAlign: 'center', borderRadius: '100px', paddingTop: '13px' }}>
                                        <FontAwesomeIcon icon={faMoneyBill} color="#fff" style={{ fontSize: '22px' }} />
                                    </div>
                                </div>
                                <div style={{ flex: 1 }}>
                                    <h5>Harga terjangkau</h5>
                                    <p>Anda tidak akan menemukan layanan undangan online lain yang lebih murah dari idinvitebook dengan kualitas layanan yang setara.</p>
                                </div>
                            </div>
                            <div style={{ display: 'flex' }}>
                                <div style={{ width: '100px' }}>
                                    <div style={{ width: '50px', height: '50px', background: '#FF3190', textAlign: 'center', borderRadius: '100px', paddingTop: '13px' }}>
                                        <FontAwesomeIcon icon={faPalette} color="#fff" style={{ fontSize: '22px' }} />
                                    </div>
                                </div>
                                <div style={{ flex: 1 }}>
                                    <h5>Banyak Pilihan Tema</h5>
                                    <p>Tersedia banyak pilihan tema siap pakai yang dapat di sesuaikan dengan selera Anda kapan saja tanpa batas.</p>
                                </div>
                            </div>
                            <div style={{ display: 'flex' }}>
                                <div style={{ width: '100px' }}>
                                    <div style={{ width: '50px', height: '50px', background: '#FF3190', textAlign: 'center', borderRadius: '100px', paddingTop: '13px' }}>
                                        <FontAwesomeIcon icon={faBandAid} color="#fff" style={{ fontSize: '22px' }} />
                                    </div>
                                </div>
                                <div style={{ flex: 1 }}>
                                    <h5>Tim Support Responsif</h5>
                                    <p>Tidak perlu menunggu lama, selesaikan masalah Anda dengan cepat secara real time melalui live chat whatsapp bersama tim support kami.</p>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div style={{ background: '#1D202C', color: "#fff", padding: '60px 0px', overflow: 'hidden' }} className="text-center">
                <Container id="feature" style={{ paddingTop: '50px', paddingBottom: '50px' }}>
                    <Row>

                        <Col md={12} sm={12}>
                            <div >
                                <h3 className='mt-5 mb-2' style={{ color: '#fff' }}>Undangan digital dengan fitur lengkap</h3>
                                <p style={{ color: '#fff' }}>Nikmati berbagai macam fitur yang disediakan oleh idinvitebook</p>
                                <img src="https://d1jcw7e5n1q2pt.cloudfront.net/1704675751209-feature.pn.png" className="img-fluid" />
                            </div>
                        </Col>
                    </Row>
               
                </Container>
            </div>
            {/* <div id="tutorial" style={{ background: '#F7F7FB', padding: '100px 0px' }}>
                <Container>
                    <Row>
                        <Col md="2" sm={12}></Col>
                        <Col md="8" sm={12} style={{ marginBottom: '80px' }}>

                            <h1 className='text-center mt-5 '>Buat Undangan Online   <span style={{ color: '#F63854' }}>Tanpa Koding</span>
                            </h1>
                            <h3 className='text-center mb-5'> Hanya 5 Menit!</h3>

                            <p className='text-center mb-5'>Cukup tonton video berikut untuk memahami bagaimana aplikasi ini bekerja kamu tidak perlu lagi keluar banyak duit untuk membuat website undangan. luangkan waktu hanya 5 menit saja!!</p>
                            <div className='text-center'>
                                <YouTube videoId="2g811Eo7K8U" opts={opts} onReady={_onReady} />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div> */}



            <div id="pricing">
                <Container>
                    <Row>
                        
                        <Col md={12}>
                            <div className='text-center'>
                                <h1 className='text-center ' style={{ marginTop: '180px' }}>Buat Undangan yang bisa diakses dimana saja </h1>
                                <h2 className='text-center mb-5'>Tanpa biaya yang Mahal lagi</h2>
                                <h1 className='text-center mt-5'><span style={{ textDecoration: 'line-through', color: 'red' }}>Rp 599.000 </span> Rp 399.000</h1>
                                <p>*Chat CS kami Sekarang untuk mendapatkan harga terbaik</p>
                                <Button onClick={() => goToRegisterPage()}>Buat Undangan Sekarang</Button>
                            </div>
                        </Col>
                    </Row>

                </Container>
            </div>
          

            {/* <div id="themes" style={{ padding: '100px 0px' }}>
                <Container>
                    <Row>
                        <h3>Pilihan Tema</h3>
                        <p>Tersedia berbagai macam pilihan tema yang bisa dicustom sendiri</p>
                    </Row>
                    <Row>
                        <Col md={3} sm={6}>
                            <ThemeComp isRecomended={false} image="https://d3vqnu8iq57j2l.cloudfront.net/1667620171917-darktheme.png" title="Elegant Dark" link="https://demo.idinvitebook.com/winterblue"></ThemeComp>
                        </Col>
                        <Col md={3} sm={6}>
                            <ThemeComp isRecomended={false} image="https://idinvitebook.com/themes/winterblue/thumb.png" title="Blue ocean" link="https://demo.idinvitebook.com/winterblue"></ThemeComp>
                        </Col>
                        <Col md={3} sm={6}>
                            <ThemeComp isRecomended={true} image="https://idinvitebook.com/themes/winterblue/thumb.png" title="Blue ocean" link="https://demo.idinvitebook.com/winterblue"></ThemeComp>
                        </Col>
                        <Col md={3} sm={6}>
                            <ThemeComp isRecomended={false} image="https://idinvitebook.com/themes/winterblue/thumb.png" title="Blue ocean" link="https://demo.idinvitebook.com/winterblue"></ThemeComp>
                        </Col>
                        <Col md={3} sm={6}>
                            <ThemeComp isRecomended={false} image="https://idinvitebook.com/themes/winterblue/thumb.png" title="Blue ocean" link="https://demo.idinvitebook.com/winterblue"></ThemeComp>
                        </Col>
                        <Col md={3} sm={6}>
                            <ThemeComp isRecomended={true} image="https://idinvitebook.com/themes/winterblue/thumb.png" title="Blue ocean" link="https://demo.idinvitebook.com/winterblue"></ThemeComp>
                        </Col>
                        <Col md={3} sm={6}>
                            <ThemeComp isRecomended={false} image="https://idinvitebook.com/themes/winterblue/thumb.png" title="Blue ocean" link="https://demo.idinvitebook.com/winterblue"></ThemeComp>
                        </Col>
                        <Col md={3} sm={6}>
                            <ThemeComp isRecomended={false} image="https://idinvitebook.com/themes/winterblue/thumb.png" title="Blue ocean" link="https://demo.idinvitebook.com/winterblue"></ThemeComp>
                        </Col>
                    </Row>
                </Container>
            </div> */}
            <Container style={{ marginTop: '100px' }}>
                <Row>
                    <Col md={12}>
                        <h2 className='text-center mt-5 mb-4'>
                            Pertanyaan yang Sering Diajukan (FAQ)
                        </h2>
                        <FaqComp></FaqComp>
                    </Col>
                </Row>
            </Container>
            <FixedBottomComp></FixedBottomComp>
            <FooterComp></FooterComp>
        </div>
    )
}
export default HomePages