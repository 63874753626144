import React, { useEffect, useState } from 'react'
import { Navbar, NavDropdown, Container, Nav, Button, Modal } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom';
import RegisterFormComp from './registerFormComp';
const NavbarComp = () => {

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => {
        setShow(true)
    };

    let navigate = useNavigate();
    const goToRegisterPage = () => {
        navigate('/register');
    }



    return (
        <>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>New Member</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <RegisterFormComp></RegisterFormComp>
                </Modal.Body>

            </Modal>
            <Navbar id="navtop" fixed='top' expand="lg" style={{ color: '#fff', }}>
                <Container>
                    <Navbar.Brand href="#home">
                        <img src="https://d1jcw7e5n1q2pt.cloudfront.net/1704675562861-Idinvitebo.png" width="140px" />

                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="me-auto">
                            <Nav.Link href="#home">Home</Nav.Link>
                            <Nav.Link href="#feature">Fitur</Nav.Link>
                            {/* <Nav.Link href="#tutorial">Tutorial</Nav.Link> */}
                            <Nav.Link href="#pricing">Harga</Nav.Link>
                            <Nav.Link href="#portfolio">Portfolio</Nav.Link>
                            {/* <Nav.Link href="#themes">Tema</Nav.Link> */}

                        </Nav>
                        <Nav >
                            <Nav.Link href="https://app.idinvitebook.com/login" style={{ color: '#fff' }}>Login</Nav.Link>
                            <Button onClick={() => handleShow()}>Daftar</Button>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </>

    )
}

export default NavbarComp