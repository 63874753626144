import {Button} from 'react-bootstrap'
import { useNavigate } from 'react-router-dom';
const FixedBottomComp = () => {
    let navigate = useNavigate();
    const goToRegisterPage = () => {
        navigate('/register');
    }
    return (
        <>
            <div className="fixbottombar">
                <Button onClick={() => goToRegisterPage()}>Miliki Sekarang</Button>
            </div>
        </>
    )
}

export default FixedBottomComp