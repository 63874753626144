import React, { useEffect } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import FooterComp from '../component/FooterComp'
import NavbarComp from '../component/NavbarComp'
import RegisterFormComp from '../component/registerFormComp'

const RegisterPages = () => {

    useEffect(() => {
        import("react-facebook-pixel")
            .then((x) => x.default)
            .then((ReactPixel) => {
                ReactPixel.init("1610568986009768");
                ReactPixel.track('AddToCart');

            });
    }, [])
    return (
        <>
            <NavbarComp></NavbarComp>
            <div style={{ marginTop: '100px' }}></div>
            <Container>
                <Row style={{maxHeight:'900px',overflow:'hidden'}}>
                    <Col md={7} sm={12} xs={{order:2}} sm={{order:2}}>
                        <div >

                            <img src="https://d1jcw7e5n1q2pt.cloudfront.net/1704676344331--Desain-ta.png" className='img-fluid' style={{marginTop:'50px',marginBottom:'100px'}} />
                        </div>
                    </Col>
                  
                    <Col md={5} sm={12} xs={{order:1}} sm={{order:1}}>
                        <div className='mt-5 mb-4'>
                            <RegisterFormComp></RegisterFormComp>
                        </div>
                    </Col>
                </Row>
            </Container>
            <FooterComp></FooterComp>
        </>

    )
}

export default RegisterPages