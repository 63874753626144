import React from 'react'
import './../App.css';
import NavbarComp from './../component/NavbarComp';
import FooterComp from './../component/FooterComp';
import { Container, Row, Col, Button } from 'react-bootstrap';
import PricingComp from '../component/PricingComp';

const PricingPages = () => {
    return (
        <>
            <NavbarComp></NavbarComp>
            <Container className="mt-5">
                <Row>
                    <Col md={6}>
                        <PricingComp data={{
                            paket: "Personal",
                            desc: "Bayar ketika anda butuh membuat undangan online (sekali bayar)",
                            price: "Rp 120.000/one time",
                        }}></PricingComp>

                    </Col>
                    <Col md={6}>
                        <PricingComp data={{
                            paket: "Vendor",
                            desc: "Berlanggan tiap bulan dengan jumlah undangan tak terbatas",
                            price: "Rp 100.000/month",
                        }}></PricingComp>
                    </Col>
                </Row>
            </Container>
            <FooterComp></FooterComp>
        </>
    )
}
export default PricingPages