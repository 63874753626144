import React from 'react'
import './../App.css';
import { Link } from 'react-router-dom';
const FooterComp = () => {
    return <div class="container">
        <div class="d-flex flex-wrap justify-content-between align-items-center py-3 my-4 border-top">
            <p class="col-md-4 mb-0 text-muted">© 2022 idinvitebook.com</p>

            <Link to="/" class="col-md-4 d-flex align-items-center justify-content-center mb-3 mb-md-0 me-md-auto link-dark text-decoration-none">
                {/* <svg class="bi me-2" width="40" height="32"><use xlink:href="#bootstrap"></use></svg> */}
            </Link>

            <ul class="nav col-md-4 justify-content-end">
                {/* <li class="nav-item"><Link to="/" class="nav-link px-2 text-muted">Home</Link></li>
                <li class="nav-item"><Link to="/feature" class="nav-link px-2 text-muted">Fitur</Link></li>
                <li class="nav-item"><Link to="/tutorial" class="nav-link px-2 text-muted">Tutorial</Link></li>
                <li class="nav-item"><Link to="/pricing" class="nav-link px-2 text-muted">Paket</Link></li> */}
            </ul>
        </div>
    </div>
}

export default FooterComp;