import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { Row, Col, Card } from 'react-bootstrap'
const FeatureComp = ({ data }) => {
    return (
        <div>
            <div className="mt-2 mb-2 text-center">
                <Row>
                    <Col md={12}>
                        <div style={{margin:'20px auto',marginBottom:'25px',textAlign: 'center', width: "65px", height: '65px', textAlign: 'center', background: '#E92B8A', borderRadius: '100px', paddingTop: '15px', fontSize: '25px',color:'#fff' }}>
                            <FontAwesomeIcon icon={data.icon} style={{fontSize:'36px'}} />
                        </div>

                    </Col>
                    <Col md={12}>
                        <Card.Title><b>{data.title}</b></Card.Title>
                        <Card.Text>
                            {data.desc}
                        </Card.Text>
                    </Col>
                </Row>
            </div>
        </div>
    )
}
export default FeatureComp